import React from 'react'
import Layout from '../components/layout';
import { Container } from 'react-bootstrap';

export default () => (
  <Layout>
    <Container fluid="xl">
      <div className="form">
        <h1>Thank you!</h1>
      </div>
    </Container>
  </Layout>
)
